<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
      {{ $route.query.name }} Workflows
    </h1>
    <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    <Card class="mb-5">
      <div class="flex justify-between items-center p-5">
        <div>
          <span class="text-darkPurple font-semibold text-base">
            Set up approval flows for sensitive actions or privileges. The
            approval process can be automatic or manual depending on your
            preferences
          </span>
        </div>

        <div class="flex gap-3">
          <Button class="bg-dynamicBackBtn text-white rounded" @click="newWorkFlow()">
            New Workflow
          </Button>
        </div>
      </div>
    </Card>
    <Card class="p-5 mb-5">
      <template v-slot:footer>
        <card-footer
          printcard
          reloadcard
          @print="printWorkFlow"
          @reload="reload()"
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
        />
      </template>
    </Card>
    <div>
      <Table
        :headers="headers"
        :items="tableData"
        style="width: 100%; height: 58px"
        class="w-full"
        :loading="loading"
        :has-checkbox="false"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.workFlowName">
            {{ item.data.workFlowName }}
          </span>
          <span v-if="item.locationNames">
            <span v-for="(location,index) in item.data.locationNames" :key="index">
              {{ `${location}${(index === item.data.locationNames.length - 1) ? '' : ',' }  ` }}
            </span>
          </span>
          <span v-if="item.functionNames">
            <span v-if="item.data.functionNames.length > 0">
            <span v-for="(functions,index) in item.data.functionNames" :key="index">
              {{ `${functions}${(index === item.data.functionNames.length - 1) ? '' : ',' }  ` }}
            </span>
            </span>
            <span v-else>---</span>
          </span>
          <span v-if="item.roleNames">
            <span v-if="item.data.roleNames.length > 0">
            <span v-for="(roles,index) in item.data.roleNames" :key="index">
              {{ `${roles}${(index === item.data.roleNames.length - 1) ? '' : ',' }  ` }}
            </span>
            </span>
            <span v-else>---</span>
          </span>
          <span v-if="item.approversCount">
            {{ item.data.approversCount }}
          </span>
          <span v-if="item.id">
            <Menu left top="-150" margin="24" class="my-2 p-0">
              <template v-slot:title>
                <icon icon-name="more_icon" size="xs" />
              </template>
              <div style="width: 157px; height: auto" class="py-3 px-2">
                <div
                  class="flex w-full py-1 px-3 more h-8 cursor-pointer"
                  @click="viewWorkFlow(item)"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name=" text-mediumSeaGreen mr-2"
                    size="xs"
                  />
                  <p class="pt-1 text-sm">View & Edit</p>
                </div>
                <div
                  class="flex py-1 px-3 more h-8 cursor-pointer"
                  @click.prevent="deleteWorkFlow(item)"
                >
                  <icon
                    icon-name="delete"
                    class-name="mr-2 text-desire"
                    size="xs"
                  />
                  <p class="pt-1 text-sm">Delete</p>
                </div>
              </div>
            </Menu>
          </span>
        </template>
      </Table>
    </div>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this workflow?
          </p>
          <div class="flex">
            <Button class="bg-dynamicBackBtn text-white" @click="removeFromWorkFlow()">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import Button from "@/components/Button";
import Modal from "@/components/Modal";

export default {
  name: "ApprovalWorkFlow",
  components: {
    Card,
    CardFooter,
    Modal,
    Table,
    Menu,
    Button,
  },
  data() {
    return {
      loading: true,
      headers: [
        { title: "Description", value: "workFlowName" },
        { title: " Location ", value: "locationNames" },
        { title: "Function", value: "functionNames" },
        { title: "Job Role", value: "roleNames" },
        { title: "Approvers ", value: "approversCount" },
        { title: "", value: "id", image: true },
      ],
      tableData: [],
      delData: "",
      dialogDel: false,
    };
  },
  methods: {
    async getAllWorkflow() {
      await this.$_getAllWorkflow(this.$route.params.actionId).then(
        (result) => {
          this.tableData = result.data.approval;
          this.loading = false;
        }
      );
    },
    printWorkFlow() {},
    async deleteWorkFlow(item) {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "deleteWorkflowSub-category"
        );
        this.deleteModal(item);
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    deleteModal(item) {
      this.dialogDel = true;
      this.delData = item.data.id;
    },

    async removeFromWorkFlow() {
      const response = await this.$_removeWorkflowFromList(this.delData);
      if (response) {
        this.tableData.splice(this.delData);
        this.dialogDel = false;
      }
    },
    async newWorkFlow() {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "createNewWorkflowSub-category"
        );
        this.$router.push({
          name: "NewWorkflow",
          params: {
            actionId: this.$route.params.actionId,
            actionSlug: this.$route.params.actionSlug,
          },
          query: { actionName: this.$route.query.name },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async viewWorkFlow(item) {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "viewWorkflowSub-category"
        );
        this.$router.push({
          name: "ViewWorkflow",
          params: { workflowId: item.data.id },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
  },
  mounted() {
    this.getAllWorkflow();
  },
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
